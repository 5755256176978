import { POSService } from "@/model/api/POS";
import { IBaseApiAttachmentModel } from "@/model/common/BaseApiModel";
import { AttachmentTypeEnum, AttachmentIdEnum } from "@/model/enums/AttachmentTypeEnum";
import { POSAddRecursiveServiceStepEnum, POSAddServiceStepEnum, POSAddSingleServiceStepEnum, ServicePathsEnum } from "@/modules/pos/router/POSRoutesEnum";
import { FileUploadDialog } from "@components";
import { posServiceService } from "@services/posService.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import POSAddSingleServicePage from "../../portfolio/POSAddSingleServicePage";

@Options({
  components: {
    FileUploadDialog
  },
  emits: ['goStep']
})
export default class AttachmentsStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSService;

  @Prop({default: ServicePathsEnum.STANDARD})
  readonly path!: string;

  attachment_type: AttachmentTypeEnum = null;
  attachment_id: AttachmentIdEnum = null;
  displayFileUploadDialog: boolean = false;
  displayExamFileUploadDialog: boolean = false;

  examAttachments: IBaseApiAttachmentModel[] = [];

  async removeAttach(attach: IBaseApiAttachmentModel) {
    this.dataModel.attachments = this.dataModel.attachments.filter(a => a.id != attach.id);
    if (attach.path_url) {
      await posServiceService.deleteAttachment(attach.id);
    }
  }

  updateAttachments(attach: IBaseApiAttachmentModel) {
    this.dataModel.attachments.push(attach);
    this.$successMessage(this.$t('upload_success'));
  }

  updateExamAttachments(attach: any) {
    this.examAttachments.push(attach);
    this.$successMessage(this.$t('upload_success'));
  }

  maxFileSize() {
    return process.env.MAX_FILE_SIZE || 10000000;
  }

  goBack() {
    this.$emit('goStep', POSAddServiceStepEnum.CHOOSE_SERVICE);
  }

  goNext() {
    if (this.path == ServicePathsEnum.SINGLE_SERVICE)
      this.$emit('goStep', POSAddSingleServiceStepEnum.CALENDAR);
    else if (this.path == ServicePathsEnum.RECURRING_SERVICE)
      this.$emit('goStep', POSAddRecursiveServiceStepEnum.CALENDAR);
    else
      this.$emit('goStep', POSAddServiceStepEnum.SUMMARY);
  }

  showFileUploadDialog() {
    this.attachment_type = AttachmentTypeEnum.GENERIC;
    this.displayFileUploadDialog = true; 
  }

  showExamFileUploadDialog() {
    this.attachment_type = AttachmentTypeEnum.EXAM_PREP;
    this.displayFileUploadDialog = true; 
  }
}
