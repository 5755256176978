import { POSService } from "@/model/api/POS";
import { Service } from "@/model/api/Service";
import { authStore } from "@/modules/auth/store";
import { POSAddServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { posService } from "@services/pos.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ServiceDuration } from "@components";
import {
  Form as VForm,
  Field as VField,
  ErrorMessage as VError
} from 'vee-validate';

@Options({
  components: {
    VForm,
    VField,
    VError,
    ServiceDuration
  },
  emits: ['goStep']
})
export default class ChooseServiceStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly path!: string;

  @Prop()
  readonly dataModel!: POSService;

  services: Service[] = [];

  async created() {
    await this.$waitFor(async () => {
      this.services = await posService.getAvailableServices(this.me.entity_id);
    });
  }

  get selectedService() {
    return this.services.find(s => s.id == this.dataModel?.service?.id) || null;
  }

  set selectedService(value) {
    this.dataModel.service = value;
  }

  get me() {
    return authStore.getters.me;
  }

  get isNextEnabled() {
    return this.dataModel.service && this.dataModel.duration;
  }

  goAttachments() {
    this.$emit('goStep', POSAddServiceStepEnum.ATTACHMENTS);
  }
}
