import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { POSPortfolio, POSService } from '@/model/api/POS';
import { PaymentData, PaymentResultData } from '@/model/api/Payment';
import { ProviderService as ProviderServiceModel, ProviderTechnicianCalendar } from '@/model/api/Provider';
import { Service } from '@/model/api/Service';
import { POSPortfolioType } from '@/model/enums/POSEnum';
import axios from 'axios';
import moment from 'moment';
import { CRUD } from './base/crud';
import { Coupon } from '@/model/api/Coupon';

class POSPortfolioService extends CRUD<POSPortfolio, any> {
    readonly endPoint = `pos-portfolio`;

    public indexByPOS(posId: number, params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        return this.get<PaginatedResponse<POSPortfolio>>(`pos/${posId}/portfolio`, { params, cancelToken });
    }

    public getServicesCompatible(type: POSPortfolioType) {
        return this.get<POSService[]>(`${this.endPoint}/services-compatible`, {
            params: { type }
        });
    }

    public getProviderServicesCompatible() {
        return this.get<Service[]>(`${this.endPoint}/provider-services-compatible`);
    }

    public getProviderServicesCompatibleByService(serviceId: number) {
        return this.get<ProviderServiceModel[]>(`${this.endPoint}/provider-services-compatible/${serviceId}/providers`);
    }

    public getProviderServiceCalendar(providerServiceId: number, month: Date) {
        return this.get<ProviderTechnicianCalendar[]>(`${this.endPoint}/provider-service-calendar/${providerServiceId}`, {
            params: {
                month: month ? moment(month).format('YYYY-MM') : null
            }
        });
    }

    public getAvailableCoupons(serviceId: number, providerId: number) {
        return this.get<Coupon[]>(`coupons/available`, {
            params: {
                source_id: providerId,
                service_id: serviceId
            }
        });
    }

    public bookProviderService(provider_service_id: number, service_date_at: string) {
        return this.post<PaymentData>(`${this.endPoint}/book-provider-service`, {            
            provider_service_id,
            service_date_at
        });
    }

    public confirmProviderService(portfolioId: number, payment_result_data: PaymentResultData) {
        return this.post<POSPortfolio>(`${this.endPoint}/${portfolioId}/confirm-provider-service`,
            payment_result_data
        );
    }

    public buyProviderService(provider_service_id: number, service_date_at: string) {
        return this.post<POSPortfolio>(`${this.endPoint}/buy-provider-service`, {            
            provider_service_id,
            service_date_at
        });
    }

    public getCalendar(month?: Date, date?: Date) {
        return this.get<POSPortfolio[]>(`${this.endPoint}/calendar`, {
            params: {
                month: month ? moment(month).format('YYYY-MM') : null,
                date: date ? moment(date).format('YYYY-MM-DD') : null
            }
        });
    }
}

export const posPortfolioService = new POSPortfolioService();